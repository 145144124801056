import { useState, useEffect, useRef, useReducer } from "react";
import { useNavigate, useOutletContext } from "react-router-dom";
import $, { data } from "jquery";
import DataTable from "datatables.net";
import { MainArea, MainAreaHeader, MainAreaBody } from "../layout/MainArea";
import { getDataTableDom, requestToServer } from "../utils/appUtils";
import TableDT from "../UI/TableDT";
import { MapContainer, Marker, Popup, TileLayer, useMapEvents } from "react-leaflet";
import L from 'leaflet';
import 'leaflet/dist/leaflet.css';
import { useMap } from 'react-leaflet'
// import { reducerModalWindow } from "../UI/ModalWindow";
// import "../KNAP/Knap.css"
import icon from "../../images/car-marker.png";
// import iconShadow from 'leaflet/dist/images/marker-shadow.png';



const ROUTE_URL = "/bbox_table";

function DataBB(props) {
    clearInterval(window.timer)

    // const [paramsModalWindow, dispatchModalWindow] = useReducer(reducerModalWindow, { show: false });

    const [serverData, setServerData] = useState(true);
    const [bBoxIDData, setBBoxIDData] = useState(true);
    const [pickerDate, setPickerDate] = useState('');

    const { showErrorPage, messageBox } = useOutletContext();
    const { showErrorPageMap, messageBoxMap } = useOutletContext();

    const navigate = useNavigate();
    // const position = [ 55.751244, 37.618423]
    const [position, setPosition] = useState(null)

    let DefaultIcon = L.icon({
        iconUrl: icon,
        iconSize: [32], iconAnchor: [16, 16],
        // shadowUrl: iconShadow
    });
    L.Marker.prototype.options.icon = DefaultIcon;
    L.Marker.prototype.options.keyboard = false;

    const refMap = useRef()
    // const mapElms = { polyLineRef: null };
    var polylines = [];

    const handleAddRow = async (e) => {
        let url;
        if (pickerDate.length > 1) {
            url = 'https://blackbox.ficom-it.info/api/bbox_table' + `/${e}` + `?date=${pickerDate}`
        } else {
            url = 'https://blackbox.ficom-it.info/api/bbox_table' + `/${e}`
        }
        let response = await fetch(url, {
            // method: 'POST',
            headers: {
                'Content-Type': 'application/json;charset=utf-8'
            },
        });
        if (response.ok) {
            // console.log('polylinespolylinespolylinespolylines', polylines);
            // polylines.forEach(function (item) {
            //     console.log('itemitemitemitem', item);
            //     refMap.current.removeLayer(item)
            // });

            console.log('refMap.currentrefMap.current', refMap);
            console.log('refMap.currentrefMap.current', refMap.current);

            let json = await response.json();
            let array = json.data;
            console.log('arrayarrayarrayarray', array);
            let lat = array[0]?.lat;
            let long = array[0]?.long;
            if (array.length == 0) {
                if (pickerDate == '') {
                    alert("На сегодняшнюю дату нет записей")
                } else {
                    alert('На дату ' + new Date(pickerDate)?.toLocaleDateString("ru-RU") + ' нет записей')
                }
            } else {
                console.log('polylinespolylines', polylines);
                polylines.map((e) => {
                    console.log('eeeeeeeeeeeeeeeeeeeeeee', e);
                    console.log('refMap.currentrefMap.current', refMap.current);
                    refMap.current.removeLayer(e)
                })
                // polylines?.forEach((item) => {
                //     console.log('itemitemitemitem', item);
                //     console.log('refMap.currentrefMap.current', refMap.current);
                //     refMap.current.removeLayer(item)
                // });
                refMap.current.setView({ lat: lat, lng: long }, 15);
            }

            if (array.length == 1) {
                var polyline = L.polyline([[lat, long], [lat, long]], { color: 'red', weight: 10 }).addTo(refMap.current);
                polylines.push(polyline);
            }

            for (let index = 0; index < array.length - 1; index++) {
                let latitude = array[index].lat;
                let longitude = array[index].long;
                // console.log('latitude', latitude, 'longitude', longitude);
                // if (array.length == 1) {
                //     var polyline = L.polyline([[latitude, longitude], [latitude, longitude]], { color: 'red', weight: 10 }).addTo(refMap.current);
                //     polylines.push(polyline);
                // } else {
                let latitude1 = array[index + 1].lat;
                let longitude1 = array[index + 1].long;
                var polyline = L.polyline([[latitude, longitude], [latitude1, longitude1]], { color: 'red', weight: 3 }).addTo(refMap.current);
                polylines.push(polyline);
                // }
            }
        } else {
            alert("Ошибка HTTP: " + response.status);
        }
    };

    useEffect(() => {
        // requestToServer(ROUTE_URL, { method: 'GET' }, setServerData, showErrorPage, true);
        requestToServer('/iddevice', { method: 'GET' }, setBBoxIDData, showErrorPage, true);
        // requestToServer(ROUTE_URL + "?crud", { method: 'GET' }, setServerData, showErrorPage, true);
        // requestToServer(ROUTE_URL + "?track", { method: 'GET' }, setPosition, showErrorPageMap, true);
    }, []);

    useEffect(() => {
        if (position?.data.length) {

            refMap.current.panTo([position.data[0].latitude, position.data[0].longitude])
        }
    }, [position])

    if (serverData === null) {
        return <MainArea loading />;
    } else {
        let data = serverData.data
        console.log('datadata', data);
        let bbdata = bBoxIDData.data
        console.log('bbdatabbdata', bBoxIDData);
    }

    const datepicker = async (e) => {
        setPickerDate(e);
        console.log('pickerDatepickerDate', pickerDate);
    };

    return (
        <MainArea>
            {/* <MainAreaHeader caption="Связь с КНАП" /> */}
            <div className="card-body">
                {/* <TableDT getOptions={() => dtTable.getTableOptions()} afterCreate={handleAfterDTCreate} /> */}

                <input type="date" id="birthday" name="birthday" onChange={(e) => datepicker(e.target.value)} ></input>

                <div className="cont-button">
                    {bBoxIDData?.data?.length && bBoxIDData?.data?.map((e, i) => {
                        return (
                            <div key={i}><button type="button" className="btn btn-primary btn-sm btn-our" onClick={() => handleAddRow(e.id_device)}>{e.id_device}</button></div>
                        )
                    })}
                </div>

                <MapContainer ref={refMap} center={{ lat: position?.data[0]?.latitude || 55.751244, lng: position?.data[0]?.longitude || 37.618423 }} zoom={13}>
                    <TileLayer
                        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                    />
                    {position?.data[0].latitude && position?.data[0]?.longitude && (
                        <Marker position={[position?.data[0]?.latitude, position?.data[0]?.longitude]}></Marker>
                    )}
                </MapContainer>
            </div>
        </MainArea>
    );
}

let dtTable = {
    Api: null,
    getTableOptions: () => {
        const dtOptions = {
            ordering: true, lengthChange: false, filter: false, paging: false, info: false, processing: true,
            order: [[1, "asc"]],
            ajax: { type: "GET", url: ROUTE_URL },
            dom: getDataTableDom("lfip", 2, 1),
            columns: [
                // { orderable: false, searchable: false, data: null, className: "dt-center td-btn-action", render: DataTable.render.btnDelete(), },
                // { data: "id_device", title: "id девайса", className: "dt-center td-btn-action", render: DataTable.render.text(), },
                { data: "id_device", title: "id девайса", render: DataTable.render.dataToAnchor("btnShowId"), },
                { data: "lat", title: "Широта", render: DataTable.render.text(), },
                { data: "long", title: "Долгота", render: DataTable.render.text(), },
                { data: "angle", title: "Угол", render: DataTable.render.number(), },
                { data: "speed", title: "Скорость", render: DataTable.render.number(), },
                { data: "timestamp", title: "Временная метка", render: DataTable.render.datetime(), },
            ],
        };
        // if ((crud & 1) === 0) {
        dtOptions.order = [[0, "asc"]];
        // dtOptions.columns.splice(0, 1);
        dtOptions.dom = getDataTableDom("lfip", 1, 1);
        // }

        console.log('dtOptions', dtOptions);


        return dtOptions;
    }
};

export default DataBB;
