import CardSystems from "./cardSystems";
import CardAbonents from './cardAbonents';
import CardLogins from './cardLogins';
import CardMonitor from './cardMonitor';
import { MainArea, MainAreaHeader, MainAreaBody } from "../layout/MainArea";
import DataBB from "./DataBB";


const Home = () => {

  return (
    //   <div className="container-fluid px-3 py-1 dashboard">
    //     <div className="row g-3">
    //       {/* <div className="col-md-12 col-lg-12 col-xl-6"> */}
    //       <CardAbonents dashboard={true} />
    //       {/* </div> */}
    //       {/* <div className="col-md-12 col-lg-12 col-xl-6">
    //         <CardSystems dashboard={true} />
    //       </div>
    //       <div className="col-md-12 col-lg-12 col-xl-6">
    //         <CardMonitor dashboard={true} />
    //       </div>
    //       <div className="col-md-12 col-lg-12 col-xl-6">
    //         <CardLogins dashboard={true} url="/dashboard/lastLogins" />
    //       </div> */}
    //     </div>
    //   </div>

    <>
      <MainArea>
        <MainAreaBody>
          {/* <CardCHConn dashboard={true} butchState={butchState} /> */}
          {/* <CardAbonents dashboard={true} /> */}
          <DataBB dashboard={true} />
        </MainAreaBody>
      </MainArea>
    </>
  );

}

export default Home;

